<template>
  <window-default ref="window" :resolve="find" :proccessData="proccessData" :mock="mock" :props="props" v-slot="{data}"
                  @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
          >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Descricao:"
          >
            <erp-input autofocus v-model="model.nome" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Parente:"
          >
            <erp-select v-model="model.parent" placeholder="Sem relação / raiz" :options="tiposFiltered"
                        :loading="loadingTipos" size="2">
              <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}"
                      :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{props.opt.label}}</span>
              </template>
            </erp-select>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <e-btn label="Remover relação parente" @click="model.parent = null" />
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr class="m-t m-b font-bold">
        <e-col>
          Definição de informações para leilão
          <u-icon class="font-14 text-blue-grey-6 cursor-pointer" name="help" />
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Montagem do título do bem:"
          >
            <textarea class="w-full wrapper-xs erp-input" v-model="model.montagemTitulo" rows="2">
            </textarea>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Montagem da subtítulo do bem:"
          >
            <textarea class="w-full wrapper-xs erp-input" v-model="model.montagemSubtitulo" rows="2">
            </textarea>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Montagem da descrição do bem:"
          >
            <textarea class="w-full wrapper-xs erp-input" v-model="model.montagemDescricao" rows="2">
            </textarea>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Montagem da observação do bem:"
          >
            <textarea class="w-full wrapper-xs erp-input" v-model="model.montagemObservacao" rows="2">
            </textarea>
          </erp-s-field>
        </e-col>
      </e-row>

      <!-- TODO: Campos -->

      <e-row mr class="m-t font-bold">
        <e-col>
          Campos Adicionais
          <u-icon class="font-14 text-blue-grey-6 cursor-pointer" name="help" />
        </e-col>
      </e-row>
      <e-row mr class="m-b m-t-n-sm text-blue-grey-6 font-11">
        <e-col>
          Cadastre campos adicionais para se adaptarem ao cadastro de bem cujo tipo seja <strong>{{model.nome}}</strong>.
          <br>
          Este campos serão apresentados no cadastro do bem e também na vistoria, para seu devido preenchimento.<br><br>
          <div class="font-thin"><strong class="text-negative">Atenção:</strong> Existe a herança de campos configurados
            em tipos de bem parentes. Para substituí-lo basta criá-lo aqui com o mesmo nome definido no tipo parente.
          </div>
        </e-col>
      </e-row>

      <e-row class="b-b m-b bg-grey-3 wrapper" v-for="(campo, index) in model.campos" :key="campo.id">
        <e-col>
          <e-row class="m-t" mr>
            <e-col style="max-width: 340px">
              <erp-s-field
                  view="tl"
                  label="Nome"
                  class="field-label-bold"
                  label-width="90px"
              >
                <erp-input size="2" v-model="campo.nome" />
              </erp-s-field>
            </e-col>
            <e-col class="flex items-center" style="height: 60px">
              <a class="text-negative" @click="removeCampo(campo)">remover este campo</a>
            </e-col>
          </e-row>

          <e-row class="m-t-n">
            <e-col>
              <erp-s-field
                  label="Obrigatório"
              >
                <erp-checkbox v-model="campo.obrigatorio" />
              </erp-s-field>
            </e-col>
          </e-row>

          <e-row mr>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Definir um valor padrão inicial"
              >
                <erp-input size="2" v-model="campo.valorPadrao[0]" />
              </erp-s-field>
            </e-col>
          </e-row>

          <e-row mr>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Configurar uma validação (regex)"
              >
                <erp-input size="2" v-model="campo.validacao[0]" />
              </erp-s-field>
            </e-col>
          </e-row>
        </e-col>
      </e-row>
      <e-btn @click="adicionarCampo" label="Adicionar novo campo" />
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox, ErpSelect} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {
  findTiposBem as find,
  newTipoBem as _new,
  updateTipoBem as update,
  listTiposBem
} from '../../../../../domain/cadastro/services'
import save from '../../../helpers/save'
import {treeCheck} from '../helpers/treeCheck';

let mockCampo = {
  id: null,
  codigo: null,
  nome: null,
  tipo: 1, // TMP: Text. TODO: Adaptar mais tipos
  obrigatorio: false,
  validacao: [],
  valorPadrao: []
}

let mock = {
  id: null,
  nome: null,
  montagemTitulo: null,
  montagemSubtitulo: null,
  montagemDescricao: null,
  montagemObservacao: null,
  parent: null,
  active: true,
  campos: []
}

export default {
  name: 'Window',
  components: {ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox, ErpSelect},
  props: ['props'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      loadingTipos: false,
      tipos: []
    }
  },
  mounted () {
    this.loadingTipos = true
    listTiposBem(200, 1, null, true)
      .then(({data}) => {
        this.tipos = data
        this.loadingTipos = false
      })
      .catch(error => {
        console.error(error)
      })
  },
  computed: {
    find () {
      return find
    },
    mock () {
      return mock
    },
    tiposFiltered () {
      return this.treeCheck(this.tipos)
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save,
    proccessData (data) {
      let _mock = JSON.parse(JSON.stringify(this.mock))
      data = Object.assign(_mock, data)

      if (data.parent && data.parent.id) {
        data.parent = data.parent.id
      }
      return data
    },
    treeCheck: treeCheck,
    adicionarCampo () {
      this.model.campos.push(JSON.parse(JSON.stringify(mockCampo)))
    },
    removeCampo (campo) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um campo',
        message: 'Você tem certeza que deseja remover este campo do cadastro do tipo de bem? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        this.model.campos.splice(this.model.campos.indexOf(campo), 1)
      }).catch(() => {
      })
    }
  }
}
</script>
